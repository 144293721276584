import React, {useEffect, useState} from "react";
import {
    Asset,
    DEFAULT_IMAGE_URL,
    extractIdFrom,
    GridGallerySkeleton,
    PropertyDetails
} from "@renaissancerentals/renaissance-component";
import {ItemSlider} from "@contentmunch/muncher-ui";
import {
    assetUrlFrom,
    getAssetsFrom,
    getAssetUrl
} from "@renaissancerentals/renaissance-component/lib/asset/service/AssetService";
import {GalleryModal} from "@renaissancerentals/renaissance-component/lib/gallery/GalleryModal";
import "./assets/PropertyHero.scss";

export const PropertyHero: React.FC<PropertyHeroProps> = ({property}) => {
    const [assets, setAssets] = useState<Asset[]>([]);
    const [isAssetsLoading, setIsAssetsLoading] = useState(true);
    const [assetInFocus, setAssetInFocus] = useState<Asset>({} as Asset);
    const [assetIndex, setAssetIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const imageClickedHandler = (image: Asset) => {
        setShowModal(true);
        setAssetInFocus(image);
        setAssetIndex(assets.findIndex(value => value.id === image.id));
    };
    const modalCloseHandler = () => {
        setShowModal(false);
        setAssetInFocus({} as Asset);
    };

    useEffect(() => {
        const currentAssets: Asset[] = [];

        if (property.photosFolderId) {
            getAssetsFrom(property.photosFolderId).then(galleryAssets => {
                currentAssets.push(...galleryAssets);

            }).finally(() => {
                setAssets(currentAssets);
                setIsAssetsLoading(false);
            });
        } else {
            currentAssets.push({id: extractIdFrom(property.coverImage), name: "cover image"} as Asset);
            setAssets(currentAssets);
            setIsAssetsLoading(false);
        }

    }, [property.photosFolderId, property.coverImage])

    return (
        isAssetsLoading ? <GridGallerySkeleton/> :
            <section className="section-property--hero">
                <GalleryModal assets={assets} assetInFocus={assetInFocus}
                              setAssetInFocus={setAssetInFocus} showModal={showModal}
                              assetIndex={assetIndex} setAssetIndex={setAssetIndex}
                              propertyId={property.id}
                              modalCloseHandler={modalCloseHandler}/>
                <ItemSlider sliderItems={assets.map((asset, index) =>
                    <>
                        <img className="property-hero--image"
                             src={assetUrlFrom(asset.id, property.id)}
                             alt={asset.name}
                             onClick={() => imageClickedHandler(asset)}
                        />
                        {index === 0 ? <div className="hero-image-info">
                            <div className="hero-image--content">
                                <img className="property-hero--icon"
                                     src={property?.logo ? getAssetUrl(property.logo, property.id) : DEFAULT_IMAGE_URL}
                                     alt="Property Icon"/>
                            </div>
                        </div> : ""}

                    </>
                )}/>

            </section>
    );
};

export interface PropertyHeroProps {
    property: PropertyDetails
}
